const colorPalette = {
  jade: '#02B875',
  celery: '#A0D76A',
  candlelight: '#FFD012',
  sunrise: '#ffad1e',
  scotchMist: '#fffade',
  pearlLusta: '#fffdf6',
  dodger: '#448ee1',
  amethyst: '#af64ce',
  permission: '#E43538',
  ferrari: '#C82124',
  mintCream: '#f6feff',
  athens: '#f9f9f9',
  smoke: '#f3f5f7',
  porcelain: '#ebeef0',
  zircon: '#dadfe3',
  chateau: '#acb6be',
  raven: '#6f7c87',
  elephant: '#183247',
  rhino: '#284255',
  white: '#ffffff',
  jungle: '#00A886',
  azure: '#2C5CC5',
  skylight: '#e5f2fd',
  clara: `#283DA5`,
  casablanca: '#E86F25',
  separator: '#E5E5E5',
  crescent: '#FAFAFA',
  peachGreen: '#F8FDFF',
  coralBlack: '#E0E0E0',
  darkCream: 'rgba(188, 203, 221, 0.54)',
  litchi: '#E0F4F0',
  litchib: '#B4E4DA',
  mangoYellow: '#FFFAE6',
  transparent: 'rgba(255, 255, 255, 0)',
  translucentBlue: '#2E6ED8',
  oceanBlue: '#2B5BC4',
  lightBlue: '#5B6AB9',
  knighthood: '#F7F8FA',

  blackKnight: '#13344E',
  orangeBlush: '#FFD6A3',
  orangeIQ: `#CB6040`,
  minuteGrey: '#9a9a9a',
  oceanDarkBlue: '#c3c7d6',
  minuteGreyDark: '#333333',
  orangePeach: '#C4602B',
  orangePeachBg: '#FFFCED',
  orangeSummary: '#E64E21',

  dragonGold: '#C3844F',
  dragonRed: '#c74040',
  blueBoxShadow: 'rgba(64, 58, 143, 0.13)',
  blueBoxSummary: 'rgba(223, 240, 255, 1)',
  creamBlueOutline: 'rgba(0, 103, 244, 0.247)',
  creamBlue: '#8EA1B9',
  blackSeparator: '#707070',
  passion: '#D72D30',
  ghostBlack: '#AAB6C9',
  pulpyOrange: '#FF9300',
  peach: '#FFD0D6',
  babylon: '#F89FA1',
  coral: '#F2797B',
  darkBlackBoxShadow: '#00000029',
  e: {
    900: '#12344D',
    800: '#264966',
    700: '#345C7C',
    600: '#447093',
    500: '#527FA5',
    400: '#6B92B6',
    300: '#83A6C8',
    200: '#A2C0DE',
    100: '#BEDBF5',
    50: '#DFF0FF'
  },
  s: {
    900: '#27313A',
    800: '#384551',
    750: '#3F3F3F',
    700: '#475867',
    600: '#576C7D',
    500: '#647A8E',
    400: '#7B8E9F',
    300: '#92A2B1',
    200: '#B1BDC8',
    125: '#ced7de',
    100: '#CFD7DF',
    85: '#D0D7DF',
    80: '#DEDEDE',
    75: '#E6E8EB',
    65: '#E7EBEF',
    50: '#EBEFF3',
    25: '#F5F7F9',
    10: '#F7F9FA',
    5: '#fcfdfd'
  },
  c: {
    900: '#D7461F',
    800: '#E16023',
    700: '#E86F25',
    600: '#EF7E27',
    500: '#F48928',
    400: '#F69A3C',
    300: '#F8AB59',
    200: '#FAC386',
    100: '#FDDBB5',
    50: '#FEF1E1'
  },
  j: {
    900: '#005C3F',
    800: '#00795B',
    700: '#008969',
    600: '#009A79',
    500: '#00A886',
    400: '#1CB697',
    300: '#4DC4AA',
    200: '#82D5C2',
    100: '#B4E5DA',
    50: '#E0F5F1'
  },
  p: {
    900: '#C82124',
    800: '#D72D30',
    700: '#E43538',
    600: '#F73F3E',
    500: '#FF473E',
    400: '#FF5959',
    300: '#F2797B',
    200: '#F89FA1',
    100: '#FFD0D6',
    50: '#FFECF0'
  },
  a: {
    900: '#283DA5',
    800: '#2C5CC5',
    700: '#2E6ED8',
    600: '#2F80EB',
    500: '#2E8EFA',
    400: '#459FFC',
    300: '#64B0FC',
    200: '#90C6FE',
    150: '#95ADE2',
    100: '#BBDCFE',
    50: '#E5F2FD'
  },
  black: '#000000'
}

export default colorPalette
